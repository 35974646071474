@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
  z-index: 1000000 !important;
  position: relative;
}

.vl {
  border-left: 4px solid #988ab5;
  height: 450px;
  width: 4.5px;
}

/*Center*/
.dentistry {
  height: 500px;
  width: 600px;
}

.inputsignup {
  border: solid !important;
  border-color: #342c49 !important;
  border-width: 0.1rem !important;
}

.inputsignup:focus {
  border-color: #988ab5 !important;
  outline: #988ab5 !important;
}

/*Booking*/
.carousel .slick-dots {
  display: none !important;
}

/* Dashboard */
.dashnav {
  background-color: #fbf8ff !important;
}

/* Consultation List */
.ant-table {
  font-size: 1rem !important;
  font-family: "Poppins" !important;
  color: #342c49 !important;
  background-color: #fbf8ff !important;
}

.ant-table-cell {
  color: #342c49 !important;
  background-color: #fbf8ff !important;
}

.actionbtn:hover {
  color: #988ab5 !important;
}

input::file-selector-button {
  background: #988ab5 !important;
  border-radius: 0.3rem !important;
  margin-left: 1rem !important;
}

/* Profile */
.ant-form label {
  color: #342c49 !important;
  font-size: 1.2rem !important;
  margin-top: 1rem !important;
}

.ant-select.profilepicture {
  border: none !important;
  padding: 0rem !important;
  font-size: 1rem !important;
}

.ant-select-selector {
  background-color: #342c49 !important;
  border: none !important;
  padding: 0rem !important;
  font-size: 1rem !important;
  height: 4rem !important;
}

.ant-select-selection-search-input {
  height: 8rem !important;
  margin-bottom: 0rem !important;
}

.ant-select-selection-item {
  margin-top: 0.5rem !important;
  border-radius: 1rem !important;
  color: rgb(209 213 219) !important;
  margin-left: 1rem !important;
}

.ant-select-selection-item::after {
  margin-top: 0.5rem !important;
}

.ant-select-selection-placeholder {
  color: rgb(209 213 219) !important;
  margin-left: 1rem !important;
  margin-top: 1rem !important;
}

/* Consultation */
.ant-select-selector.consultationselect {
  margin-top: 0rem !important;
}
.ant-picker-input input::placeholder {
  color: rgb(209 213 219) !important;
  margin-top: 2rem !important;
}
.ant-picker-suffix {
  color: rgb(209 213 219) !important;
  margin-top: 0.8rem !important;
  font-size: 1.2rem !important;
}

.ant-picker-input input {
  color: rgb(209 213 219) !important;
  margin-top: 1rem !important;
}

.ant-picker-clear {
  margin-right: 1.5rem !important;
  margin-top: 0.5rem !important;
  color: #988ab5 !important;
  background-color: #342c49 !important;
}

.ant-btn {
  background-color: #342c49 !important;
}

.ant-btn.consultationbooking {
  background-color: #fbf8ff !important;
  border: none !important;
  box-shadow: none !important;
}

.consultationbookingtext {
  font-size: 1.4rem !important;
  color: #342c49 !important;
}
/* 
.ant-form-item {
  margin-bottom: 0rem !important;
} */

/* Input Search */
.ant-input-group {
  border-color: #342c49 !important;
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
  background-color: #fbf8ff !important;
}
.ant-input-search-button {
  background-color: #fbf8ff !important;
  height: 2.6rem !important;
  box-shadow: none !important;
  border-color: gray !important;
}
.anticon-search {
  color: gray !important;
}
@layer components {
  .sidebar {
    transition: all 0.4s ease-in-out;
  }
}
